<template>
  <v-container solid fluid tag="section" id="company-playlist">
    <div :class="gs.isMobile() ? 'mx-auto text-center' : 'mx-13'">
      <img
        style="max-width: 180px; max-height: 180px"
        src="../../../../assets/dxa-invest.png"
      />
      <v-row align="center">
        <h1 class="company-name" :class="gs.isMobile() ? 'mx-auto' : ''">
          {{ company.Name }}
        </h1>
        <v-spacer v-if="!gs.isMobile()"></v-spacer>
        <v-btn
          :class="gs.isMobile() ? 'mx-auto' : ''"
          color="secondary"
          @click="$router.go(-1)"
          ><span style="text-transform: uppercase">{{
            $t("go_back")
          }}</span></v-btn
        >
      </v-row>
    </div>
    <div>
      <v-row justify="center" class="mx-10">
        <v-col
          v-for="(video, i) in videos"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          lg="4"
          class="my-0 py-0"
        >
          <iframe
            :src="`https://player.vimeo.com/video/${video}?portrait=0&byline=0&title=0`"
            width="100%"
            :height="gs.isMobile() ? '250' : '350'"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            style="margin-bottom: 10px; margin-top: 10px; max-height: 350px"
          ></iframe>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped>
@media only screen and (max-width: 600px) {
  .company-name {
    font-size: 3rem !important;
  }
}
.company-name {
  font-size: 5.5rem;
  font-weight: bold;
  color: var(--primary);
}
</style>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "CompanyPlaylist",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    company: false,
    videos: null,
  }),
  async created() {
    this.loading = true;
    await this.get_company_basic_info();
    this.loading = false;
  },
  async mounted() {},
  methods: {
    async get_company_basic_info() {
      await this.apiService
        .getRequest(`company/basic/${this.$route.params.company_id}`)
        .then((resp) => {
          this.company = JSON.parse(resp.message);
          if (
            this.company.DetailsVideo != null &&
            this.gs.isJson(this.company.DetailsVideo)
          ) {
            this.company.DetailsVideo = JSON.parse(this.company.DetailsVideo);
            this.videos =
              this.company.DetailsVideo[this.$i18n.locale].split(";");
          }
        })
        .catch((error) => {});
    },
  },
};
</script>
